import logo from './logo.svg';
import './App.css';
import './fonts/Playfair_Display/PlayfairDisplay-VariableFont_wght.ttf'
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

function App() {



  return (
    <div className="pt-5 pl-5 md:pt-20 md:pl-20 w-full content-center flex justify-center">

        <div className="w-full  h-50">
            <header className={"font-face-Menlo text-5xl md:text-6xl font-bold bg-clip-text text-transparent bg-gradient-to-r " +
                "leading-tight animate-gradient-x from-indigo-500 via-purple-500 to-pink-500 " +
                "justify-start pb-1"}>
                Kyle Petkovic
            </header>
            <div className={"h-fit"}>
                <h1 className={"font-face-Menlo justify-start text-xl h-fit"}>CS @ UMass, class of 2023</h1>
            </div>
            <div className={"pb-5 md:pt-1 w-fit"}>
                <a className={"flex"} href={"https://resume.kyle.pet"} target="_blank">
                <h1 className={"font-face-Menlo text-3xl md:text-4xl font-bold" +
                    "justify-start"}>
                    <a className={"justify-center text-center font-face-Menlo"}>
                        📄&nbsp;
                    </a>
                    <a className={"underline justify-center text-center font-face-Menlo z-10"}>
                        Resumé
                    </a>
                </h1>
                </a>
            </div>

        </div>

    </div>
  );
}

export default App;
