import './App.css';
import React, { useEffect, onMouseEnter, onMouseLeave, useRef } from 'react';

import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Typography,
} from "@material-tailwind/react";

export default function Projects({projectName, projectInfo, location, dates, imageLocation}) {

    let bounds;
    const inputRef = useRef();
    const glowRef = useRef();
    const rotateToMouse = (e) => {
        function isMobile() {
            const userAgent = navigator.userAgent.toLowerCase();
            const mobileKeywords = ['android', 'iphone', 'ipod', 'ipad', 'windows phone'];
            return mobileKeywords.some(keyword => userAgent.includes(keyword));
        }

        if(isMobile() === false){
            bounds = inputRef.current.getBoundingClientRect();
            const mouseX = e.clientX;
            const mouseY = e.clientY;
            const leftX = mouseX - bounds.x;
            const topY = mouseY - bounds.y;
            const center = {
                x: leftX - bounds.width / 2,
                y: topY - bounds.height / 2,
            };
            const distance = Math.sqrt(center.x ** 2 + center.y ** 2);

            inputRef.current.style.transform = `
      scale3d(1.07, 1.07, 1.07)
      rotate3d(
        ${center.y / 100},
        ${-center.x / 100},
        0,
        ${Math.log(distance) * 2}deg
      )
    `;
            glowRef.current.style.backgroundImage = `
      radial-gradient(
        circle at
        ${center.x * 2 + bounds.width / 2}px
        ${center.y * 2 + bounds.height / 2}px,
        #ffffff55,
        #0000000f
      )
    `;
        }
    };
    const removeListener = (e) => {
        inputRef.current.style.transform = '';
        inputRef.current.style.background = '';
    };
    useEffect(() => {});


  return (
    <div className="appP w-full md:w-96">
        <Card className="card overflow-hidden"
        ref={inputRef}
        onMouseLeave={removeListener}
        onMouseMove={rotateToMouse}>
            <div ref={glowRef} className="glow" />
            <CardBody className="text-start ">
                <Typography variant="h5" className="mb-2 font-face-Menlo">
                    { projectName }
                </Typography>
                <Typography className={"font-face-Menlo"}>
                    { projectInfo }
                </Typography>
            </CardBody>
            <div className={"h-full"}/>
            <CardFooter divider className="flex items-center justify-between py-3 align-bottom">
                <Typography variant="small" className={"font-face-Menlo"}>{ dates }</Typography>
                <Typography variant="small" color="gray" className="flex gap-1 font-face-Menlo">
                    <i className="fas fa-map-marker-alt fa-sm mt-[3px]" />
                    { location }
                </Typography>
            </CardFooter>
        </Card>
    </div>
  );
}
