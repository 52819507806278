import React, { useState } from "react";
import './App.css';
import SVG from 'react-inlinesvg';
const Footer = () => {

    return(
        <div >

            <div className={"bg-[#F8FBFC] h-80 mt-16 b-10"}>
                <p className={"pt-24 text-center font-face-Menlo"}>
                    Made with ❤️ in California
                </p>️
                <div className={"bg-[#F8FBFC] w-1/2 mx-auto text-center"}>
                </div>

                <div  className={"bg-[#F8FBFC] justify-center mx-auto text-center content-center text-clip"}>
                    <div className={"flex content-center justify-center"}>
                        <a href={"https://www.linkedin.com/in/kylepet/"} target="_blank" className={"underline bg-[#F8FBFC] flex justify-end font-face-Menlo content-end"} rel="noreferrer">
                            <SVG className={"pb-1 align-bottom content-end"}
                                 src="./icons/linkedIn.svg"
                                 width={23}
                                 height="auto"
                                 title="LinkedIn Icon"
                            />
                                KylePet
                        </a>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <a href={"mailto:kyle@petkovic.me"} className={"font-face-Menlo justify-start content-start flex"}>

                            <a className={"bg-[#F8FBFC] justify-start font-face-Menlo pr-0.5"}>✉️</a>
                            <a className={"underline justify-start bg-[#F8FBFC] font-face-Menlo"}>
                                Kyle@Petkovic.me
                            </a>
                        </a>


                    </div>
                    <a className={"flex"} href={"https://photos.google.com/share/AF1QipM96vsjne7Twydj69KgaJw_AT4-RHK8smLIooX-sJZjE12cWaUUQ1ZDp_NHyotKNQ?key=a2VkMXlYM2huV3hCVzYtQm5HWjZ2OHQ0bXljU3Zn"} target="_blank">
                    <div className={"justify-center mx-auto text-center content-center text-clip font-face-Menlo"}>
                        🐱
                        <a className={"underline"}>
                            Cats
                        </a>

                    </div>
                    </a>
                </div>
                <div className={"h-10"}></div>
            </div>
        </div>

    );
}
export default Footer
